<template>
  <div>
    <PageHeaderDisplay
      title="Integrations"
      sub-title="Manage everything to do with automation tasks."
      category="Automation"
      category-logo="fa-conveyor-belt"
      :category-route="{ name: $route.name }"
    />

    <TabView route-name="automation" :tabs="tabs">
      <router-view />
    </TabView>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

import PageHeaderDisplay from '@/components/PageHeaderDisplay';
import TabView from '@/components/TabView';

export default {
  name: 'AutomationView',
  components: {
    PageHeaderDisplay,
    TabView
  },
  computed: {
    ...mapGetters({
      accounts: 'account/accounts',
      company: 'company/company'
    }),
    tabs() {
      return [
        {
          name: 'Automations',
          route: 'automation-list',
          icon: 'fa-conveyor-belt'
        },
        {
          name: 'Meters/Accounts',
          route: 'automation-meters-list',
          icon: 'fa-meter-bolt'
        }
      ];
    }
  }
};
</script>
